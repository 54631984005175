import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { useWeb3Modal, useSwitchNetwork, useDisconnect, useWeb3ModalProvider, useWeb3ModalAccount, useWeb3ModalState } from '@web3modal/ethers5/react';
import { useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import * as web3 from '@solana/web3.js';
import { Program, AnchorProvider, BN, utils } from '@project-serum/anchor';
import { MINIMAL_BRIDGE_IDL } from './minimal_bridge_idl';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL, clusterApiUrl, Connection, ComputeBudgetProgram } from '@solana/web3.js';
import {
  TOKEN_PROGRAM_ID,
  getOrCreateAssociatedTokenAccount,
  createTransferInstruction,
    getAssociatedTokenAddress,
    createAssociatedTokenAccount,
    createAssociatedTokenAccountInstruction,
} from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import { EXPANDED_BRIDGE_IDL } from './minimal_bridge_idl';
import './App.css';
import Spinner from './Spinner.js';
import MyTokenABI from './MyToken.json';
import BridgeABI from './Bridge.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedeemModal from './components/redeemModal';
import { keccak256 } from 'ethereum-cryptography/keccak';

// Import images
import tokenBackground from './assets/images/token.png';
import dogitoImage from './assets/images/dogito.png';
import ethereumImage from './assets/images/ethereum.svg';
import arbitrumImage from './assets/images/arbitrum.svg';
import vectorImage from './assets/images/Vector.svg';
import arrowImage from './assets/images/arrow.svg';
import vector3Image from './assets/images/Vector3.svg';
import DownArrow from './assets/downArrow.png';
import binance from './assets/images/binance.svg';
import baseimg from './assets/images/base.svg';
import solanaimg from './assets/images/Solana_logo.png';

const blockchainOptions = [
  { label: 'Ethereum', value: 'ethereum', image: ethereumImage, contractAddress: '0x488542C2320F20D65405a1C03DA769Bc124F9A28', chainId: 1, rpcUrl: 'https://cloudflare-eth.com', bridgeContract: '0xE5004F8b2Ed9D58D156D8984ab7D3E981d5a083e' },
  { label: 'Binance Smart Chain', value: 'binance', image: binance, contractAddress: '0x05311d9aA0E17D1071986146CeD510C85c71B52f', chainId: 56, rpcUrl: 'https://bsc-dataseed4.bnbchain.org', bridgeContract: '0xcaCD1be79b53aaBeC3c5c86AD31e3643faC53f73' },
  { label: 'BASE', value: 'base', image: baseimg, contractAddress: '0x488542C2320F20D65405a1C03DA769Bc124F9A28', chainId: 8453, rpcUrl: 'https://mainnet.base.org', bridgeContract: '0x8d00DfbeBbD8945A7A66AC8a94f7d04E831B2FD2' },
  { label: 'Solana', value: 'sol', image: solanaimg, contractAddress: 'AYgw6k8ZzjkmTMAqrV8hbBe53uM8CrWwcbHuk5RMh6Qt', chainId: 103, rpcUrl: 'https://light-floral-river.solana-mainnet.quiknode.pro/24fe3cba2cf2a0f20c850f18c06e914106b955cd/', bridgeContract: 'C4j9g3Sp7XC3kkJxBju7zypkfSfPBcd2kLrvbL6xqQeT' },
];

// const blockchainOptions = [
//   { label: 'Ethereum', value: 'ethereum', image: ethereumImage, contractAddress: '0x488542C2320F20D65405a1C03DA769Bc124F9A28', chainId: 1, rpcUrl: 'https://cloudflare-eth.com', bridgeContract: '0xBFAD2A8e728adc165EAF448dcf6A4dC41f497510' },
//   { label: 'Binance Smart Chain', value: 'binance', image: binance, contractAddress: '0x824602426596Aaa4DEeC0D0642aB11Ca55276Ef1', chainId: 97, rpcUrl: 'https://data-seed-prebsc-2-s1.bnbchain.org:8545', bridgeContract: '0xAC6d4C02469010D451226296854F82C413E32c9c' },
//   { label: 'BASE', value: 'base', image: baseimg, contractAddress: '0x2D7dD8AeF2488c54312e91AcD52ee15120E20Da3', chainId: 421614, rpcUrl: 'https://arbitrum-sepolia.blockpi.network/v1/rpc/public', bridgeContract: '0x396b1AC056962b7e6b03b50Dbb68c9F06A25f9b7' },
//   { label: 'Solana', value: 'sol', image: solanaimg, contractAddress: 'GvkBPHKFYscCPP9AncN5YNVenbabY7vYXPrWg3NfYYXW', chainId: 103, rpcUrl: 'https://api.devnet.solana.com', bridgeContract: 'DZnkkTmCiFWfYTf41X3Rd1kDgozqzxWaHqsw6W4x2oe' },
// ];

const CustomButton = ({ onClick, disabled, children }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className="bg-[#EC4899] flex justify-center items-center rounded-[8px] h-[44px] text-black font-liberation font-light w-full"
    style={{ fontSize: '16px' }}
  >
    {children}
  </button>
);

function App() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [fromBlockchain, setFromBlockchain] = useState('binance');
  const [toBlockchain, setToBlockchain] = useState('base');
  const [sendAmount, setSendAmount] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);
  const [toTokenBalance, setToTokenBalance] = useState(0);
  const [gasFee, setGasFee] = useState(0);
  const [routerFee, setRouterFee] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [network, setNetwork] = useState(true);
  const web3modal = useWeb3Modal();
  const [transferStatus, setTransferStatus] = useState('');
  const [txHash, setTxHash] = useState('');
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
  // const { switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { selectedNetworkId } = useWeb3ModalState();
  const solanaWallet = useWallet();

  const getNativeTokenSymbol = (blockchain) => {
    switch (blockchain) {
      case 'ethereum':
        return 'ETH';
      case 'binance':
        return 'BNB';
      case 'base':
        return 'ETH';
      case 'sol':
        return 'SOL';
      default:
        return 'ETH';
    }
  };

  const connectWallet = async () => {
    setIsProcessing(true);
    try {
      if (fromBlockchain === 'sol') {
        // Solana wallet connection is handled by WalletMultiButton
        if (!solanaWallet.connected) {
          toast.info('Please connect your Solana wallet using the button');
        }
      } else {
        await web3modal.open();
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const isSolanaToWalletConnected = () => {
    return toBlockchain === 'sol' && solanaWallet.connected;
  };


  const handleFromBlockchainChange = async (e) => {
    const newFromBlockchain = e.target.value;
    if (newFromBlockchain === toBlockchain) {
      const newToBlockchain = blockchainOptions.find(option => option.value !== newFromBlockchain)?.value;
      if (newToBlockchain) {
        setToBlockchain(newToBlockchain);
        await updateToBlockchainBalance(newToBlockchain);
      }
    }
    setFromBlockchain(newFromBlockchain);
    await fetchTokenBalance();
    await fetchGasFee();
    checkNetwork();
  };

  const handleToBlockchainChange = async (e) => {
    const newToBlockchain = e.target.value;
    if (newToBlockchain === fromBlockchain) {
      const newFromBlockchain = blockchainOptions.find(option => option.value !== newToBlockchain)?.value;
      if (newFromBlockchain) {
        setFromBlockchain(newFromBlockchain);
        await fetchTokenBalance();
      }
    }
    setToBlockchain(newToBlockchain);
    await updateToBlockchainBalance(newToBlockchain);
  };

  const updateToBlockchainBalance = async (blockchain) => {
    if (blockchain === 'sol') {
      if (solanaWallet.connected) {
        await fetchToTokenBalance(null, blockchain);
      }
    } else if (isConnected && address) {
      await fetchToTokenBalance(address, blockchain);
    }
  };

  const fetchSolanaTokenBalance = async (address, mintAddress) => {
    const connection = new web3.Connection("https://light-floral-river.solana-mainnet.quiknode.pro/24fe3cba2cf2a0f20c850f18c06e914106b955cd/");
    const pubKey = new web3.PublicKey(address);
    const tokenMintAddress = new web3.PublicKey(mintAddress);
    
    try {
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(pubKey, { mint: tokenMintAddress });
      if (tokenAccounts.value.length > 0) {
        const balance = tokenAccounts.value[0].account.data.parsed.info.tokenAmount.uiAmount;
        return balance.toString();
      }
      return '0';
    } catch (error) {
      console.error('Error fetching Solana token balance:', error);
      return '0';
    }
  };

  const fetchTokenBalance = async () => {
    const selectedBlockchain = blockchainOptions.find(option => option.value === fromBlockchain);

    if (selectedBlockchain.value === 'sol') {
      if (!solanaWallet.connected || !solanaWallet.publicKey) {
        setTokenBalance(0);
        return;
      }

      try {
        const balance = await fetchSolanaTokenBalance(solanaWallet.publicKey.toString(), selectedBlockchain.contractAddress);
        setTokenBalance(balance);
      } catch (error) {
        console.error('Error fetching Solana token balance:', error);
        toast.error('Error fetching Solana token balance');
        setTokenBalance(0);
      }
    } else {
      if (!isConnected || !address) {
        setTokenBalance(0);
        return;
      }

      try {
        const provider = new ethers.providers.JsonRpcProvider(selectedBlockchain.rpcUrl);
        const contract = new ethers.Contract(
          selectedBlockchain.contractAddress,
          MyTokenABI,
          provider
        );
        const balance = await contract.balanceOf(address);
        setTokenBalance(ethers.utils.formatUnits(balance, 18));
      } catch (error) {
        console.error('Error fetching EVM token balance:', error);
        toast.error('Error fetching EVM token balance');
        setTokenBalance(0);
      }
    }
  };

  const fetchToTokenBalance = async (address, blockchain) => {
    if (!address && blockchain !== 'sol') {
      setToTokenBalance(0);
      return;
    }
  
    const selectedToBlockchain = blockchainOptions.find(option => option.value === blockchain);
  
    if (selectedToBlockchain.value === 'sol') {
      if (!solanaWallet.connected || !solanaWallet.publicKey) {
        setToTokenBalance(0);
        return;
      }
      try {
        const balance = await fetchSolanaTokenBalance(solanaWallet.publicKey.toString(), selectedToBlockchain.contractAddress);
        setToTokenBalance(balance);
      } catch (error) {
        console.error('Error fetching Solana token balance for To network:', error);
        toast.error('Error fetching Solana token balance for To network');
        setToTokenBalance(0);
      }
    } else {
      try {
        const provider = new ethers.providers.JsonRpcProvider(selectedToBlockchain.rpcUrl);
        const contract = new ethers.Contract(
          selectedToBlockchain.contractAddress,
          MyTokenABI,
          provider
        );
        const balance = await contract.balanceOf(address);
        setToTokenBalance(ethers.utils.formatUnits(balance, 18));
      } catch (error) {
        console.error('Error fetching token balance for To network:', error);
        toast.error('Error fetching token balance for To network');
        setToTokenBalance(0);
      }
    }
  };

  const fetchGasFee = async () => {
    if ((!isConnected || !address) && (!solanaWallet.connected || !solanaWallet.publicKey)) {
      console.error('Wallet not connected');
      return;
    }

    const selectedBlockchain = blockchainOptions.find(option => option.value === fromBlockchain);

    if (selectedBlockchain.value === 'sol') {
      // Solana gas fee estimation logic here
      // This is a placeholder, you'll need to implement Solana-specific gas estimation
      setGasFee(0.000005); // Example value
    } else {
      try {
        const provider = new ethers.providers.JsonRpcProvider(selectedBlockchain.rpcUrl);
        const feeData = await provider.getFeeData();
        const gasPrice = feeData.gasPrice;
        const gasLimit = await provider.estimateGas({ to: address, value: 0 });
        const gasFee = gasPrice.mul(gasLimit);
        setGasFee(ethers.utils.formatUnits(gasFee, 'ether'));
      } catch (error) {
        console.error('Error fetching gas fee:', error);
        toast.error('Error fetching gas fee');
        setGasFee(0);
      }
    }
  };

  const handleSendAmountChange = (e) => {
    let amount = e.target.value;
    if (parseFloat(amount) > parseFloat(tokenBalance)) {
      amount = tokenBalance;
    }
    setSendAmount(amount);

    if (amount > 0) {
      const routerFee = (amount * 1) / 100;
      const receiveAmount = amount - routerFee;
      setRouterFee(routerFee);
      setReceiveAmount(receiveAmount);
    } else {
      setRouterFee(0);
      setReceiveAmount(0);
    }
  };

  const handleMaxClick = () => {
    const maxAmount = tokenBalance;
    setSendAmount(maxAmount);
    const routerFee = (maxAmount * 1) / 100;
    const receiveAmount = maxAmount - routerFee;
    setRouterFee(routerFee);
    setReceiveAmount(receiveAmount);
  };

  const switchNetwork = async (chainId) => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      await provider.send("wallet_switchEthereumChain", [{ chainId: ethers.utils.hexValue(chainId) }]);
    } catch (error) {
      if (error.code === 4902) {
        try {
          const selectedToBlockchain = blockchainOptions.find(option => option.chainId === chainId);
          const provider = new ethers.providers.Web3Provider(walletProvider);
          await provider.send("wallet_addEthereumChain", [
            {
              chainId: ethers.utils.hexValue(chainId),
              chainName: selectedToBlockchain.label,
              rpcUrls: [selectedToBlockchain.rpcUrl],
              nativeCurrency: {
                name: selectedToBlockchain.label,
                symbol: getNativeTokenSymbol(selectedToBlockchain.value),
                decimals: 18
              },
              blockExplorerUrls: [selectedToBlockchain.explorerUrl]
            },
          ]);
        } catch (addError) {
          console.error('Failed to add network:', addError);
          throw new Error(`Failed to add network: ${addError.message}`);
        }
      } else {
        console.error('Failed to switch network:', error);
        throw new Error(`Failed to switch network: ${error.message}`);
      }
    }
  };
  

  const generateCommitment = async (user, amount, lockTime, targetChain, fromBlockchain) => {
    try {
      const response = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/generate-commitment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user, amount, lockTime, targetChain, fromBlockchain })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error generating commitment:', error);
      throw new Error('Error generating commitment');
    }
  };

  const fetchNonce = async (user, contractAddress, rpcUrl) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(contractAddress, BridgeABI, provider);
      const nonce = await contract.userNonce(user);
      return nonce;
    } catch (error) {
      console.error('Error fetching nonce:', error);
      throw new Error('Error fetching nonce');
    }
  };
  
  const releaseTokensOnTargetChain = async (lockHash, secret, toBlockchain, lockInfo) => {
    console.log('Releasing tokens on target chain:', lockHash, secret, toBlockchain);
    if (toBlockchain !== 'sol') {
    try {
      setIsProcessing(true);
      const selectedToBlockchain = blockchainOptions.find(option => option.value === toBlockchain);
  
      // Switch to target network
      setTransferStatus(`Switching to ${selectedToBlockchain.label} network`);
      try {
        await switchNetwork(selectedToBlockchain.chainId);
      } catch (error) {
        console.error('Error switching network:', error);
        toast.error(`Failed to switch to ${selectedToBlockchain.label} network. Please switch manually and try again.`);
        setTransferStatus('Failed to switch network');
        return;
      }
  
      // Release tokens on target chain
      setTransferStatus('Releasing tokens on target chain');
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const targetBridgeContract = new ethers.Contract(selectedToBlockchain.bridgeContract, BridgeABI, signer);
  
      // Fetch lock details from the contract
      const lock = await targetBridgeContract.locks(lockHash);
      console.log("Lock details:", lock);

      const actualSecret = secret?.secret ? secret?.secret : secret;
      // Ensure the secret is in the correct format
      const secretBytes = ethers.utils.arrayify(
        actualSecret.startsWith('0x') ? actualSecret : '0x' + actualSecret
      );
  
      // Recreate the commitment
      const recreatedCommitment = ethers.utils.keccak256(
        ethers.utils.solidityPack(
          ['bytes32', 'address', 'uint256', 'uint256'],
          [secretBytes, lock.user, lock.amount, lock.lockTime]
        )
      );
  
      // Log all relevant information
      console.log("Inputs for commitment calculation:");
      console.log("Secret (hex):", ethers.utils.hexlify(secretBytes));
      console.log("User address:", lock.user);
      console.log("Amount:", lock.amount.toString());
      console.log("Lock time:", lock.lockTime.toString());
      console.log("Recreated commitment:", recreatedCommitment);
      console.log("Provided lockHash (commitment):", lockHash);
      console.log("Do they match?", recreatedCommitment === lockHash);
  
      // Proceed with token release
      console.log("Proceeding with releaseTokens call:");
      console.log("lockHash:", lockHash);
      console.log("secretBytes:", ethers.utils.hexlify(secretBytes));
  
      const releaseTx = await targetBridgeContract.releaseTokens(
        lockHash,
        secretBytes
      );
  
      setTransferStatus('Waiting for release confirmation');
      const receipt = await releaseTx.wait();
      
      console.log("Release transaction receipt:", receipt);
  
      setTransferStatus('Tokens released successfully');
      toast.success('Tokens released successfully on target chain');
  
      // Update balances
      await fetchTokenBalance();
      await updateToBlockchainBalance(toBlockchain);
      setSendAmount('');
      setReceiveAmount(0);
      // Attempt to delete the lock hash from the server
      try {
        const deleteResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/delete-lock-hash', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lockHash }),
        });
        if (!deleteResponse.ok) {
          console.warn('Failed to delete lock hash from server');
        }
      } catch (deleteError) {
        console.error('Error deleting lock hash:', deleteError);
      }
  
    } catch (error) {
      console.error('Error during token release:', error);
      if (error.data) {
        console.error('Error data:', error.data);
      }
      toast.error(`Error: ${error.data?.message || error.message}`);
      setTransferStatus(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  } else {
    try {
      setIsProcessing(true);
      if (!solanaWallet.connected) {
        toast.error('Solana wallet is not connected. Please connect your wallet and try again.');
        setTransferStatus('Solana wallet not connected');
        return;
      }
    
      setTransferStatus('Preparing to release tokens on Solana chain');
    
      const connection = new web3.Connection("https://light-floral-river.solana-mainnet.quiknode.pro/24fe3cba2cf2a0f20c850f18c06e914106b955cd/");
      const provider = new AnchorProvider(
        connection,
        solanaWallet,
        { preflightCommitment: 'confirmed' }
      );
      const SOLANA_PROGRAM_ID = new web3.PublicKey('C4j9g3Sp7XC3kkJxBju7zypkfSfPBcd2kLrvbL6xqQeT');
      const SOLANA_TOKEN_MINT_ADDRESS = new web3.PublicKey('AYgw6k8ZzjkmTMAqrV8hbBe53uM8CrWwcbHuk5RMh6Qt');
      const program = new Program(MINIMAL_BRIDGE_IDL, SOLANA_PROGRAM_ID, provider);
    
      console.log('Secret (hex):', secret);
    
      const [bridgeStatePda] = await web3.PublicKey.findProgramAddress(
        [Buffer.from('bridge_state_main')],
        SOLANA_PROGRAM_ID
      );
    
      const [bridgeAuthority] = await web3.PublicKey.findProgramAddress(
        [Buffer.from('bridge_authority')],
        SOLANA_PROGRAM_ID
      );
    
      const userATA = await getAssociatedTokenAddress(
        SOLANA_TOKEN_MINT_ADDRESS,
        solanaWallet.publicKey
      );
    
      const bridgeATA = await getAssociatedTokenAddress(
        SOLANA_TOKEN_MINT_ADDRESS,
        bridgeAuthority,
        true // allowOwnerOffCurve
      );
    
      console.log('Account Addresses:');
      console.log('User:', solanaWallet.publicKey.toBase58());
      console.log('Bridge Token Account:', bridgeATA.toBase58());
      console.log('User Token Account:', userATA.toBase58());
      console.log('Bridge Authority:', bridgeAuthority.toBase58());
    
      const checkAndLogAccount = async (accountName, accountAddress, expectedOwner = null) => {
        const accountInfo = await connection.getAccountInfo(accountAddress);
        console.log(`${accountName}:`);
        console.log("  Exists:", !!accountInfo);
        if (accountInfo) {
          console.log("  Owner:", accountInfo.owner.toBase58());
          if (expectedOwner) {
            console.log("  Correct owner:", accountInfo.owner.toBase58() === expectedOwner);
          }
        }
      };
    
      await checkAndLogAccount('Bridge Token Account', bridgeATA, utils.token.TOKEN_PROGRAM_ID.toBase58());
      await checkAndLogAccount('User Token Account', userATA, utils.token.TOKEN_PROGRAM_ID.toBase58());
      await checkAndLogAccount('Bridge Authority', bridgeAuthority);
    
      const balance = await connection.getBalance(solanaWallet.publicKey);
      console.log("Wallet balance:", balance / web3.LAMPORTS_PER_SOL, "SOL");
      setTransferStatus('Releasing tokens on Solana chain');
    
      const MAX_RETRIES = 3;
      let retries = 0;
      let tx;
    
      while (retries < MAX_RETRIES) {
        try {
          const { blockhash } = await connection.getLatestBlockhash('finalized');
          const amount = new BN(secret.amount);
          console.log('Attempting releaseTokens transaction...');
          console.log('Amount:', amount.toString());
          console.log('Recipient:', solanaWallet.publicKey.toBase58());
          console.log('Nonce:', new BN(secret.nonce || 0).toString());
          console.log('MerkleProof:', secret.merkleProof);
    
          const merkleProofBuffers = secret.merkleProof.map(proof => Buffer.from(proof.slice(2), 'hex'));
          console.log('MerkleProof Buffers:', merkleProofBuffers);
    
          // Client-side leaf computation for verification
          const leafBuffer = Buffer.alloc(32 + 8 + 8);
          leafBuffer.set(solanaWallet.publicKey.toBytes(), 0);
          leafBuffer.set(amount.toArrayLike(Buffer, 'le', 8), 32);
          leafBuffer.set(new BN(0).toArrayLike(Buffer, 'le', 8), 40); // nonce
          const computedLeaf = keccak256(leafBuffer);
          console.log("Client Computed Leaf:", '0x' + Buffer.from(computedLeaf).toString('hex'));
          console.log("Server Provided Commitment:", secret.commitment);
    
          const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({ 
            units: 600000 // Increased from default
          });
          
          let transaction = new Transaction().add(modifyComputeUnits);
    
          // Check if user ATA exists and create if it doesn't
          const userATAInfo = await connection.getAccountInfo(userATA);
          if (!userATAInfo) {
            console.log("Creating user ATA:", userATA.toBase58());
            transaction.add(
              createAssociatedTokenAccountInstruction(
                solanaWallet.publicKey, // payer
                userATA, // ata
                solanaWallet.publicKey, // owner
                SOLANA_TOKEN_MINT_ADDRESS // mint
              )
            );
          }
    
          const releaseTokensIx = await program.methods.releaseTokens(
            amount,
            solanaWallet.publicKey,
            new BN(secret.nonce || 0),
            merkleProofBuffers
          )
          .accounts({
            user: solanaWallet.publicKey,
            bridgeState: bridgeStatePda,
            bridgeTokenAccount: bridgeATA,
            recipientTokenAccount: userATA,
            bridgeAuthority: bridgeAuthority,
            tokenProgram: utils.token.TOKEN_PROGRAM_ID,
          })
          .instruction();
    
          transaction.add(releaseTokensIx);
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = solanaWallet.publicKey;
    
          const signedTransaction = await solanaWallet.signTransaction(transaction);
          const serializedTransaction = signedTransaction.serialize();
          tx = await connection.sendRawTransaction(serializedTransaction, {
            skipPreflight: false,
            preflightCommitment: 'confirmed'
          });
    
          break;
        } catch (e) {
          console.error(`Attempt ${retries + 1} failed:`, e);
          console.error('Error stack:', e.stack);
          console.error('Error name:', e.name);
          console.error('Error message:', e.message);
          if (e.error) {
            console.error('Inner error:', e.error);
          }
          retries++;
          if (retries >= MAX_RETRIES) {
            throw e;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    
      setTransferStatus('Waiting for release confirmation');
      
      const MAX_CONFIRMATION_TIME = 300000; // 5 minutes in milliseconds
      const POLLING_INTERVAL = 10000; // 10 seconds
    
      let confirmedTransaction = null;
      const startTime = Date.now();
    
      while (Date.now() - startTime < MAX_CONFIRMATION_TIME) {
        try {
          const signatureStatus = await connection.getSignatureStatus(tx);
          
          if (signatureStatus.value !== null) {
            if (signatureStatus.value.err) {
              throw new Error(`Transaction failed: ${JSON.stringify(signatureStatus.value.err)}`);
            }
    
            if (signatureStatus.value.confirmationStatus === 'confirmed' || 
                signatureStatus.value.confirmationStatus === 'finalized') {
              confirmedTransaction = signatureStatus;
              console.log("Transaction confirmed:", confirmedTransaction);
              break;
            }
          }
    
          console.log(`Waiting for confirmation... (${Math.round((Date.now() - startTime) / 1000)}s elapsed)`);
        } catch (confirmError) {
          console.log("Confirmation check failed, retrying...", confirmError);
        }
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
      }
    
      if (!confirmedTransaction) {
        console.log("Transaction not yet confirmed after maximum wait time. It may still confirm later.");
        setTransferStatus('Transaction pending - please check later');
        return; // Exit the function here
      }
    
      const receipt = await connection.getTransaction(tx, {
        commitment: 'confirmed',
        maxSupportedTransactionVersion: 0
      });
    
      if (receipt && receipt.meta && receipt.meta.logMessages) {
        console.log("On-chain transaction logs:");
        receipt.meta.logMessages.forEach((log, index) => {
          console.log(`  ${index}: ${log}`);
        });
      } else {
        console.log("No on-chain logs found in the transaction receipt.");
      }
    
      console.log("Release transaction signature:", tx);
    
      setTransferStatus('Tokens released successfully');
      toast.success('Tokens released successfully on Solana chain');
    
      await fetchTokenBalance();
      await updateToBlockchainBalance('sol');
      setSendAmount('');
      setReceiveAmount(0);
    
      // Only delete the lock hash if the transaction was confirmed successfully
      try {
        const deleteResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/delete-merkle-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ commitment: secret.commitment }),
        });
        if (!deleteResponse.ok) {
          console.warn('Failed to delete lock hash from server');
        } else {
          console.log('Lock hash deleted successfully');
        }
      } catch (deleteError) {
        console.error('Error deleting lock hash:', deleteError);
      }
    
    } catch (error) {
      console.error('Error during Solana token release:', error);
      console.error('Error stack:', error.stack);
      if (error.logs) {
        console.log("Error logs:");
        error.logs.forEach((log, index) => {
          console.log(`  ${index}: ${log}`);
        });
      }
      toast.error(`Error: ${error.message}`);
      setTransferStatus(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  }
  };


  const retrieveSecretKey = async (lockHash, fromBlockchain) => {
    if (fromBlockchain !== 'sol' && toBlockchain !== 'sol') {
      console.log('From blockchain:', fromBlockchain);
    try {
      console.log('Retrieving secret key:', lockHash);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const message = `I am requesting the secret for lockHash: ${lockHash}`;
      const signature = await signer.signMessage(message);
    
      const response = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/retrieve-secret', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lockHash, userAddress: address, signature })
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to retrieve secret');
      }
      
      return data.secret;
    } catch (error) {
      console.error('Error retrieving secret:', error);
      throw new Error('Error retrieving secret');
    }
  }  else if (fromBlockchain === 'sol' && toBlockchain !== 'sol') {
      try {
      const response = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/retrieve-secret', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lockHash, userAddress: address })
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to retrieve secret');
      }

      return data.secret;
    } catch (error) {
      console.error('Error retrieving secret:', error);
      throw new Error('Error retrieving secret');
    }
  } else if (fromBlockchain !== 'sol' && toBlockchain === 'sol') {
    try {
      const response = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/retrieve-secret-solana', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lockHash, userAddress: solanaWallet.publicKey.toString() })
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to retrieve secret');
      }

      return data;
    } catch (error) {
      console.error('Error retrieving secret:', error);
      throw new Error('Error retrieving secret');
    }
  }
  };  

  
  const lockTokensOnSolana = async (amount, commitment, toBlockchain, fromBlockchain, lockTime, secretfromAPI, amountAfterTaxin18Decimals) => {
    try {
      console.log("Starting lockTokensOnSolana...");
  
      const connection = new Connection("https://light-floral-river.solana-mainnet.quiknode.pro/24fe3cba2cf2a0f20c850f18c06e914106b955cd/");
      const wallet = solanaWallet; // Ensure this is your connected wallet
      if (!wallet.publicKey) throw new Error('Wallet not connected');
  
      console.log("Wallet connected:", wallet.publicKey.toString());
  
      // Define the program ID
      const programId = new PublicKey('C4j9g3Sp7XC3kkJxBju7zypkfSfPBcd2kLrvbL6xqQeT');
  
      // Create a provider
      const provider = new AnchorProvider(connection, wallet, {
        preflightCommitment: 'processed',
      });
      console.log("Provider created");
  
      // Create the program interface using the imported IDL
      const program = new Program(MINIMAL_BRIDGE_IDL, programId, provider);
      console.log("Program interface created");
  
      // Log the original amount
      console.log("Original amount (18 decimals):", amount.toString());
  
      // Convert 18 decimal amount to 8 decimal amount
      const amountIn18Decimals = new BN(amount.toString());
      const conversionFactor = new BN(10).pow(new BN(11)); // 10^10 to convert from 18 to 8 decimals
      const amountIn8Decimals = amountIn18Decimals.div(conversionFactor);
  
      console.log("Converted amount (8 decimals):", amountIn8Decimals.toString());
  
      if (amountIn8Decimals.bitLength() > 64) {
        throw new Error('Amount exceeds maximum value for u64');
      }
  
      // Mint address of the token
      const mintPublicKey = new PublicKey('AYgw6k8ZzjkmTMAqrV8hbBe53uM8CrWwcbHuk5RMh6Qt');
      console.log("Mint public key:", mintPublicKey.toString());
  
      // Fetch the user's associated token account
      const userTokenAccountAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        wallet.publicKey
      );
      console.log("User token account address:", userTokenAccountAddress.toString());
  
      // Bridge wallet public key
      const bridgeWalletPublicKey = new PublicKey('8FYPsoHo5WRs8VANyr2zYDEpKFBFf7o5DG84d9v7Yovy');
      console.log("Bridge wallet public key:", bridgeWalletPublicKey.toString());
  
      // Fetch or create the bridge token account
      const bridgeTokenAccountAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        bridgeWalletPublicKey
      );
      console.log("Bridge token account address:", bridgeTokenAccountAddress.toString());
  
      // Check if the bridge token account exists and create it if it doesn't
      const bridgeTokenAccountInfo = await connection.getAccountInfo(bridgeTokenAccountAddress);
      if (!bridgeTokenAccountInfo) {
        console.log("Bridge token account does not exist, creating...");
        const createBridgeTokenAccountIx = createAssociatedTokenAccountInstruction(
          wallet.publicKey, // Payer
          bridgeTokenAccountAddress, // Associated token account
          bridgeWalletPublicKey, // Owner
          mintPublicKey // Mint
        );
        const tx = new Transaction().add(createBridgeTokenAccountIx);
        await provider.sendAndConfirm(tx);
        console.log("Bridge token account created");
      } else {
        console.log("Bridge token account already exists");
      }
  
      // Derive the PDA for bridgeState
      const [bridgeStatePda, bridgeStateBump] = await PublicKey.findProgramAddress(
        [Buffer.from('bridge_state_main')],
        programId
      );
      console.log("Bridge state PDA:", bridgeStatePda.toString());
  
      // Derive the PDA for bridgeAuthority
      const [bridgeAuthorityPda, bridgeAuthorityBump] = await PublicKey.findProgramAddress(
        [Buffer.from('bridge_authority')],
        programId
      );
      console.log("Bridge authority PDA:", bridgeAuthorityPda.toString());
  
      // Check the current state of the bridgeState account
      const bridgeStateInfo = await connection.getAccountInfo(bridgeStatePda);
  
      if (bridgeStateInfo && !bridgeStateInfo.owner.equals(programId)) {
        console.log("Bridge state owned by incorrect program, closing and reinitializing");
        // Close the incorrectly owned account
        const closeTx = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: bridgeStatePda,
            toPubkey: wallet.publicKey,
            lamports: bridgeStateInfo.lamports,
          })
        );
        await provider.sendAndConfirm(closeTx);
        console.log("Incorrect bridge state account closed");
      }
  
      if (!bridgeStateInfo || !bridgeStateInfo.owner.equals(programId)) {
        console.log("Initializing bridge state");
        try {
          const initTx = await program.methods
            .initialize(wallet.publicKey)
            .accounts({
              payer: wallet.publicKey,
              bridgeState: bridgeStatePda,
              systemProgram: SystemProgram.programId,
            })
            .rpc();
          console.log("Initialization transaction signature:", initTx);
          await connection.confirmTransaction(initTx, 'confirmed');
          console.log("Bridge state initialized successfully");
        } catch (initError) {
          console.error("Error initializing bridge state:", initError);
          throw new Error("Failed to initialize bridge state: " + initError.message);
        }
      } else {
        console.log("Bridge state already correctly initialized");
      }
  
      console.log('Locking amount (8 decimals):', amountIn8Decimals.toString());
      console.log('Commitment:', commitment);
  
      // Define the recipient as the commitment (assumed to be a 32-byte array)
      const recipient = new Uint8Array(Buffer.from(commitment.slice(2), 'hex')); // Remove '0x' prefix and convert to Uint8Array
  
      // Define the target chain (assuming it's a numeric ID, e.g., 1 for Ethereum)
      const targetChain = 1; // Replace with actual target chain ID
  
      setTransferStatus('Waiting for transaction confirmation');
  
      // Call the transfer_tokens instruction with the 8-decimal amount
      try {
        const transaction = await program.methods
          .transferTokens(amountIn8Decimals, recipient, targetChain)
          .accounts({
            user: wallet.publicKey,
            userTokenAccount: userTokenAccountAddress,
            bridgeTokenAccount: bridgeTokenAccountAddress,
            bridgeState: bridgeStatePda,
            bridgeAuthority: bridgeAuthorityPda,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          })
          .transaction();
      
        // Get a recent blockhash
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = wallet.publicKey;
  
        // Sign the transaction
        if (typeof wallet.signTransaction === 'function') {
          const signedTx = await wallet.signTransaction(transaction);
          const txid = await connection.sendRawTransaction(signedTx.serialize());
          console.log('Transaction signature:', txid);
          
          // New confirmation logic with retry
          const maxRetries = 10;
          const retryInterval = 5000; // 5 seconds
          let confirmed = false;
          
          for (let attempt = 0; attempt < maxRetries && !confirmed; attempt++) {
            try {
              const confirmation = await connection.confirmTransaction(txid, 'confirmed');
              if (confirmation.value.err) {
                throw new Error(`Transaction failed: ${confirmation.value.err}`);
              }
              confirmed = true;
              console.log('Transaction confirmed after', attempt + 1, 'attempts');
            } catch (confirmError) {
              console.log(`Confirmation attempt ${attempt + 1} failed:`, confirmError.message);
              if (attempt < maxRetries - 1) {
                console.log(`Retrying in ${retryInterval / 1000} seconds...`);
                await new Promise(resolve => setTimeout(resolve, retryInterval));
              }
            }
          }
  
          if (!confirmed) {
            throw new Error(`Transaction not confirmed after ${maxRetries} attempts`);
          }
        } else {
          throw new Error('Wallet does not support signTransaction method');
        }
      
        console.log('Transaction confirmed');
      
        setTransferStatus('Confirming lock');
        const confirmResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/confirm-lock', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user: address,
            amount: amountAfterTaxin18Decimals.toString(),
            lockTime: lockTime,
            targetChain: toBlockchain,
            fromBlockchain: fromBlockchain,
            commitment: commitment,
            secret: secretfromAPI
          })
        });
  
        if (!confirmResponse.ok) {
          const errorData = await confirmResponse.json();
          throw new Error(`Failed to confirm lock: ${errorData.error}`);
        }
  
        const confirmData = await confirmResponse.json();
        console.log('Confirm lock response:', confirmData);
  
        setTransferStatus('Tokens locked successfully');
        toast.success('Tokens locked successfully');
      
        const data = await retrieveSecretKey(commitment, fromBlockchain);
      
        console.log('Secret:', data);
      
        await releaseTokensOnTargetChain(commitment, data, toBlockchain, data.lockData);
      } catch (transferError) {
        console.error('Error in transfer_tokens instruction:', transferError);
        throw new Error("Failed to transfer tokens: " + transferError.message);
      }
  
    } catch (error) {
      console.error('Error locking tokens on Solana:', error);
      toast.error(`Error: ${error.message}`);
    }
  };
  
  
  const generateSolCommitment = async (user, amount, lockTime, targetChain, fromBlockchain) => {
    console.log(user, amount, lockTime, targetChain, fromBlockchain);
    try {
      const response = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/generate-commitment-solana', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user, amount, lockTime, targetChain, fromBlockchain })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error generating commitment:', error);
      throw new Error('Error generating commitment');
    }
  };
  
  const handleApproveAndSend = async () => {
    setIsProcessing(true);
    setTransferStatus('Initiating transfer process');
    try {
      const selectedBlockchain = blockchainOptions.find(option => option.value === fromBlockchain);
      const selectedToBlockchain = blockchainOptions.find(option => option.value === toBlockchain);
       
      if (!sendAmount || sendAmount === '') {
        throw new Error('Amount is required');
      }
  
      const amount = ethers.utils.parseUnits(sendAmount, 18);
      const amountAfterTaxin18Decimals = ethers.utils.parseUnits(receiveAmount.toString(), 18);
      if (toBlockchain === 'sol' && !solanaWallet.connected) {
        throw new Error('Please connect your Solana wallet');
      }

      const connectWallet = async (attempts = 0) => {
        if (isConnected) {
          console.log('Wallet already connected');
          return; // Exit the function if already connected
        }
      
        while (!isConnected && attempts < 3) { // Limit attempts to 3
          console.log(`Connection attempt ${attempts + 1}`);
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second between attempts
      
          // Retry the connection
          try {
            await web3modal.open();
            if (isConnected) {
              console.log('Wallet connected successfully');
              return; // Exit the function if connection successful
            }
          } catch (error) {
            console.error('Error opening web3 modal:', error);
          }
      
          attempts++;
        }
      
        if (!isConnected) {
          setTransferStatus('Failed to connect wallet');
          throw new Error('Failed to connect wallet');
        }
      
        if (!walletProvider) {
          toast.error('Wallet provider not available. Please try reconnecting.');
          setTransferStatus('Wallet provider not available');
          throw new Error('Wallet provider not available');
        }
      };

      if (fromBlockchain === 'sol') {
        const lockDuration = 30 * 24 * 60 * 60; // 30 days in seconds
        const lockTime = Math.floor(Date.now() / 1000); // Current timestamp in seconds
        const fromBlockchain = selectedBlockchain.value;
        console.log(fromBlockchain)
        setTransferStatus(`Processing transfer...`);
        
        if (!isConnected) {
          try {
            await connectWallet();
          } catch (error) {
            console.error('Error connecting to wallet:', error);
            setIsProcessing(false);
            return;
          }
        }

        console.log("Address:", address);
        const commitment = await generateCommitment(
            address,
            amountAfterTaxin18Decimals.toString(),
            lockTime,
            toBlockchain,
            fromBlockchain
        );
        console.log('Commitment:', commitment.commitment);
        console.log('To blockchain:', toBlockchain);
        console.log('Lock duration:', lockDuration);
        console.log('Amount:', amount.toString());
        setTransferStatus('Locking tokens on Solana');
        await lockTokensOnSolana(amount, commitment.commitment, toBlockchain, fromBlockchain, lockTime, commitment.secret, amountAfterTaxin18Decimals);
        await fetchTokenBalance();
        await updateToBlockchainBalance(toBlockchain);
        await fetchGasFee();
        setIsProcessing(false);
        return;
    }
  
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      setTransferStatus('Checking allowance');
  
      const tokenContract = new ethers.Contract(
        selectedBlockchain.contractAddress,
        MyTokenABI,
        signer
      );
  
      if (!ethers.utils.isAddress(selectedBlockchain.bridgeContract) || !ethers.utils.isAddress(address)) {
        throw new Error('Invalid contract address or user address');
      }
  
      if (selectedNetworkId !== selectedBlockchain.chainId) {        
        throw new Error(`Please switch to ${selectedBlockchain.label} network`);
      }
  
      let allowance;
      try {
        allowance = await tokenContract.allowance(address, selectedBlockchain.bridgeContract);
      } catch (allowanceError) {
        console.error('Allowance call error:', allowanceError);
        throw new Error(`Allowance call error: ${allowanceError.message}`);
      }
  
      if (allowance.lt(amount)) {
        setTransferStatus('Approving token transfer');
        const approveTx = await tokenContract.approve(selectedBlockchain.bridgeContract, amount);
        await approveTx.wait();
        toast.success('Token transfer approved successfully');
      }
  
      const nonce = await fetchNonce(address, selectedBlockchain.bridgeContract, selectedBlockchain.rpcUrl);
      const lockDuration = 30 * 24 * 60 * 60; // 30 days in seconds
      const lockTime = Math.floor(Date.now() / 1000); // Current timestamp in seconds
      console.log('Amount:', amount.toString());

      let commitment;
      if (selectedToBlockchain.value === 'sol') {
      commitment = await generateSolCommitment(solanaWallet.publicKey.toString(), amountAfterTaxin18Decimals.toString(), lockTime, selectedToBlockchain.value, selectedBlockchain.value);
      } else {
      commitment = await generateCommitment(address, amountAfterTaxin18Decimals.toString(), lockTime, selectedToBlockchain.value, selectedBlockchain.value);
      }  

      setTransferStatus('Locking tokens');
      console.log('Locking tokens:', amount.toString());
      console.log('Amount after tax:', amountAfterTaxin18Decimals.toString());
      console.log('Commitment:', commitment);
      console.log('To blockchain:', selectedToBlockchain.value);
      console.log('Nonce:', nonce.toString());
      console.log('Lock duration:', lockDuration);
      const bridgeContract = new ethers.Contract(selectedBlockchain.bridgeContract, BridgeABI, signer);
      /* assign the relayer fee based on the selected blockchain */
      let relayerFee;
      if (selectedBlockchain.value === 'ethereum') {
        relayerFee = ethers.utils.parseEther('0.001');
      } else if (selectedBlockchain.value === 'base') {
        relayerFee = ethers.utils.parseEther('0.001');
      } else if (selectedBlockchain.value === 'binance') {
        relayerFee = ethers.utils.parseEther('0.005');
      }
      console.log('Relayer fee:', relayerFee.toString());
      const lockTx = await bridgeContract.lockTokens(amount, commitment.commitment, selectedToBlockchain.value, nonce, lockDuration, { value: relayerFee });
  
      setTransferStatus('Waiting for confirmation');
      const receipt = await lockTx.wait();
      
      if (toBlockchain === 'sol') {
        setTransferStatus('confirming lock');
        const confirmResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/confirm-lock-solana', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify
          ({
            commitment: commitment.commitment,
          })
        });

        if (!confirmResponse.ok) {
          const errorData = await confirmResponse.json();
          throw new Error(`Failed to confirm lock: ${errorData.error}`);
        }

        const confirmData = await confirmResponse.json();
        console.log('Confirm lock response:', confirmData);
      } else {
      // Confirm lock
      setTransferStatus('Confirming lock');
    const confirmResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/confirm-lock', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: address,
        amount: amountAfterTaxin18Decimals.toString(),
        lockTime: lockTime,
        targetChain: selectedToBlockchain.value,
        fromBlockchain: selectedBlockchain.value,
        commitment: commitment.commitment,
        secret: commitment.secret,
        txHash: lockTx.hash
      })
    });

    if (!confirmResponse.ok) {
      const errorData = await confirmResponse.json();
      throw new Error(`Failed to confirm lock: ${errorData.error}`);
    }

    const confirmData = await confirmResponse.json();
    console.log('Confirm lock response:', confirmData);
  }
      setTransferStatus('Tokens locked successfully');
      toast.success('Tokens locked successfully');
  
      const lockEvent = receipt.events.find(e => e.event === 'TokensLocked');
      const lockHash = lockEvent.args.lockHash;
  
      setTxHash(lockTx.hash);
  
      // Request the secret key
      setTransferStatus('Requesting secret key');
      console.log('From Blockchain:', selectedBlockchain.value);
      const secret = await retrieveSecretKey(commitment.commitment, selectedBlockchain.value);
      
      // // Get signature
      // setTransferStatus('Getting signature for release');
      // const { signature, messageHash } = await getSignature(commitment);
      // // Release tokens on target chain
      console.log('Releasing tokens on target chain:', lockHash);
      console.log('Secret:', secret);
      await releaseTokensOnTargetChain(commitment.commitment, commitment, selectedToBlockchain.value);
  
    } catch (error) {
      console.error('Error during token transfer:', error);
      toast.error(`Error: ${error.data?.message || error.message}`);
      setTransferStatus(`Error: ${error.message}`);
      setIsProcessing(false);
    }
  };
  

  const getSignature = async (lockId) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    
    console.log('Lock hash (lockId):', lockId);
  
    // Sign the lockId directly
    const signature = await signer.signMessage(lockId);
    console.log('Signature:', signature);
  
    return { signature };
  };


  const checkNetwork = async () => {
    try {
      const selectedBlockchain = blockchainOptions.find(option => option.value === fromBlockchain);
      if (fromBlockchain === 'sol') {
        setNetwork(solanaWallet.connected);
      } else if (selectedNetworkId !== selectedBlockchain.chainId) {
        setNetwork(false);
      } else {
        setNetwork(true);
      }
    } catch (error) {
      console.error('Error checking network:', error);
    }
  };

  const networkSwitch = async () => {
    try {
      const selectedBlockchain = blockchainOptions.find(option => option.value === fromBlockchain);
      if (fromBlockchain === 'sol') {
        // For Solana, we don't need to switch networks
        setNetwork(true);
      } else if (selectedNetworkId !== selectedBlockchain.chainId) {
        await switchNetwork(selectedBlockchain.chainId);
      }
      setNetwork(true);
    } catch (error) {
      console.error('Error switching network:', error);
    }
  }

  useEffect(() => {
    if ((fromBlockchain === 'sol' && solanaWallet.connected) || (fromBlockchain !== 'sol' && isConnected)) {
      fetchTokenBalance();
      updateToBlockchainBalance(toBlockchain);
      fetchGasFee();
      checkNetwork();
    }
  }, [isConnected, address, solanaWallet.connected, solanaWallet.publicKey, fromBlockchain, toBlockchain, selectedNetworkId, walletProvider, tokenBalance]);

  const handleWalletDisconnect = async () => {
    try {
      if (fromBlockchain === 'sol') {
        await solanaWallet.disconnect();
      } else {
        await disconnect();
      }
      setSendAmount('');
      setReceiveAmount(0);
      setTokenBalance(0);
      setToTokenBalance(0);
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
    }
  };

  const isWalletConnected = useCallback(() => {
    return (fromBlockchain === 'sol' && solanaWallet.connected) || (fromBlockchain !== 'sol' && isConnected);
  }, [fromBlockchain, solanaWallet.connected, isConnected]);

  const handleConnectWallet = useCallback(() => {
    if (fromBlockchain === 'sol') {
      // The actual connection is handled by WalletMultiButton
      return;
    } else {
      connectWallet();
    }
  }, [fromBlockchain, connectWallet]);

  const renderActionButton = () => {
    if (!isWalletConnected()) {
      return (
        <CustomButton onClick={handleConnectWallet}>
          Connect Wallet
        </CustomButton>
      );
    }
  
    if (!network) {
      return (
        <CustomButton onClick={networkSwitch}>
          {isProcessing ? <Spinner /> : <strong>Switch Network</strong>}
        </CustomButton>
      );
    }
  
    if (toBlockchain === 'sol' && !solanaWallet.connected) {
      return (
        <WalletMultiButton 
          style={{
            backgroundColor: '#EC4899',
            color: 'black',
            fontSize: '16px',
            height: '44px',
            borderRadius: '8px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 300,
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Connect Solana Wallet
        </WalletMultiButton>
      );
    }
  
    return (
      <CustomButton onClick={handleApproveAndSend} disabled={isProcessing}>
        {isProcessing ? (
          <>
            <Spinner /> <strong>{transferStatus}</strong>
          </>
        ) : (
          <strong>Approve and Send</strong>
        )}
      </CustomButton>
    );
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-4 md:p-24 bg-black bg-cover"
      style={{ backgroundImage: `url(${tokenBackground})` }}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="flex items-center gap-2 text-4xl md:text-[60px] font-bold mb-10 md:mb-[60px] text-white">
        <span>TOKEN</span>
        <span className="text-[--primary]">BRIDGE</span>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center gap-2 w-full">
        <div className="w-full md:w-auto flex justify-center mb-8 md:mb-0">
          <img src={dogitoImage} className="w-56 md:w-[450px] h-auto" alt="dogito" />
        </div>
        <div className="form-container sm:mt-[-106px] md:mt-0 home-box form-container-shadow rounded-[20px] bg-[#000000] border-[2px] p-4 md:p-[28px] border-[#FF00DD] w-full max-w-[780px] h-auto">
          <div className="flex justify-between items-center mb-6 md:mb-[28px]">
            <span className="text-[--primary] font-bold md:text-[24px]">Bridges</span>
            <button
              type="button"
              onClick={() => setIsRedeemModalOpen(true)}
              className="find-redeem-button ml-2"
            >
              Find Redeem
            </button>
          </div>
          <div className="flex flex-col gap-4 md:gap-8">
            <div className="flex flex-col md:flex-row justify-between items-end">
              <div className="flex flex-col gap-2 w-full md:w-[331px]">
                <span>From</span>
                <div className="custom-select-container">
                  <select
                    className="custom-select"
                    value={fromBlockchain}
                    onChange={handleFromBlockchainChange}
                  >
                    {blockchainOptions
                      .filter(option => option.value !== toBlockchain)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                  <img src={vectorImage} alt="Vector" className="dropdown-icon" />
                </div>
              </div>
              <span className="mb-3 hidden md:block">
                <img src={arrowImage} alt="Arrow" />
              </span>
              <div className="flex flex-col gap-2 w-full md:w-[331px]">
                <span className="m-hide text-left">To</span>
                <div className="bg-[#151515] border border-[#343434] w-[42px] h-[42px] m-auto flex justify-center items-center rounded-[8px] md:hidden mt-5">
                  <img src={DownArrow} alt="DownArrow" style={{ height: '13px', width: '15px' }} />
                </div>
                <div>
                  <span className="d-hide m-show text-left m-0 ">To</span>
                </div>
                <div className="custom-select-container">
                  <select
                    className="custom-select"
                    value={toBlockchain}
                    onChange={handleToBlockchainChange}
                  >
                    {blockchainOptions
                      .filter(option => option.value !== fromBlockchain)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                  <img src={vectorImage} alt="Vector" className="dropdown-icon" />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-end">
              <div className="flex flex-col gap-2 w-full md:w-[331px]">
                <div className="flex items-center justify-between">
                  <span>You send</span>
                  <div className="flex items-center gap-1">
                    <span onClick={handleMaxClick} style={{ cursor: 'pointer' }}>Balance: {Math.round(tokenBalance)}</span>
                    <img src={vectorImage} alt="Vector" className="dropdown-icon" />
                  </div>
                </div>
                <div className="bg-[#151515] rounded-[8px] border flex justify-between items-center px-2 border-slate-500" style={{ borderColor: '#343434' }}>
                  <div className="flex items-center gap-1 w-full md:w-[331px] h-[46px]">
                    <img src={blockchainOptions.find(option => option.value === fromBlockchain)?.image} alt="blockchain" className='w-6 h-6' />
                    <input
                      type="number"
                      value={sendAmount}
                      onChange={handleSendAmountChange}
                      className="bg-transparent border-none focus:outline-none text-white w-full no-arrows"
                      placeholder="0.00"
                      min="0"
                    />
                  </div>
                  <span>DOGA</span>
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full md:w-[331px] mt-[20px]">
                <div className="flex items-center justify-between w-full">
                  <span>You receive</span>
                  <span>Balance: {Math.round(toTokenBalance)}</span>
                </div>
                <div className="bg-[#151515] rounded-[8px] border flex justify-between items-center px-2 border-slate-500" style={{ borderColor: '#343434' }}>
                  <div className="flex items-center gap-1 w-full md:w-[331px] h-[46px]">
                    <img src={blockchainOptions.find(option => option.value === toBlockchain)?.image} alt="blockchain" className='w-6 h-6' />
                    <span>{receiveAmount}</span>
                  </div>
                  <span>DOGA</span>
                </div>
              </div>
            </div>
            <div className="h-auto md:h-[125px] bg-[#151515] flex flex-col gap-[5px] rounded-[8px] border-[1px] py-4 md:py-[17px] px-3 md:px-[13px]" style={{ borderColor: "#343434" }}>
              <div className="flex justify-between items-center">
                <span className="text-[#FF0083] text-lg md:text-[24px] font-bold">Additional Details:</span>
                <img src={vectorImage} alt="Vector" />
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#A5A5A5] text-sm md:text-[14px] font-liberation font-light">Router fee</span>
                <div className="flex items-center gap-1">
                  <span className='font-liberation text-[#A5A5A5] text-[14px] font-liberation font-bold'>{routerFee}</span>
                  <span className='font-liberation text-[#A5A5A5] text-[14px] font-liberation font-bold'>DOGA</span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-[#A5A5A5] text-sm md:text-[14px] font-liberation font-light">Gas on destination</span>
                <div className="flex items-center gap-1">
                  <span className='font-liberation text-[#A5A5A5] text-[14px] font-liberation font-bold'>{parseFloat(gasFee).toFixed(4)}</span>
                  <span className='font-liberation text-[#A5A5A5] text-[14px] font-liberation font-bold'>{getNativeTokenSymbol(fromBlockchain)}</span>
                  <img src={vector3Image} alt="Vector3" />
                </div>
              </div>
            </div>
            {fromBlockchain === 'sol' ? (
  <div className="solana-wallet-wrapper" style={{ width: '100%' }}>
    {solanaWallet.connected ? (
      <CustomButton
        onClick={handleApproveAndSend}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <>
            <Spinner /> <strong>{transferStatus}</strong>
          </>
        ) : (
          <>
            <img src={blockchainOptions.find(option => option.value === 'sol')?.image} alt="Solana" className='w-6 h-6 mr-2' />
            <strong>Approve and Send</strong>
          </>
        )}
      </CustomButton>
    ) : (
      <WalletMultiButton 
        style={{
          backgroundColor: '#EC4899',
          color: 'black',
          fontSize: '16px',
          height: '44px',
          borderRadius: '8px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 300,
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Connect Solana Wallet
      </WalletMultiButton>
    )}
  </div>
) : (
  renderActionButton()
)}
            {isWalletConnected() && (
              <button
                onClick={handleWalletDisconnect}
                type="button"
                className="text-sm text-gray-400 mt-2"
                style={{ cursor: 'pointer', marginTop: '-20px' }}
              >
                Disconnect wallet
              </button>
            )}
            {/* {txHash && (
              <div className="mt-2 text-sm text-gray-400">
                <a href={`https://testnet.bscscan.com/tx/${txHash}`} target="_blank" rel="noopener noreferrer">
                  View transaction
                </a>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {isRedeemModalOpen && (
        <RedeemModal
          isOpen={isRedeemModalOpen}
          onClose={() => setIsRedeemModalOpen(false)}
          blockchainOptions={blockchainOptions}
          setSendAmount={setSendAmount}
          setReceiveAmount={setReceiveAmount}
          fetchTokenBalance={fetchTokenBalance}
          fetchToTokenBalance={fetchToTokenBalance}
        />
      )}
    </main>
  );
}

export default App;
