import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import 'buffer';
import process from 'process';

require('@solana/wallet-adapter-react-ui/styles.css');

window.Buffer = window.Buffer || require('buffer').Buffer;
window.process = process;

const projectId = 'bda53af5f12b5129594f5375c439069e';

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
};

const bsc = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.bnbchain.org'
};

const base = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://base.org',
  rpcUrl: 'https://mainnet.base.org'
};

const chains = [mainnet, bsc, base];

const ethersConfig = defaultConfig({
  metadata: {
    name: 'Web3Modal',
    description: 'Web3Modal Example',
    url: 'https://web3modal.com',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
  },
  defaultChainId: 1,
  rpcUrl: 'https://cloudflare-eth.com'
});

createWeb3Modal({
  ethersConfig,
  chains,
  projectId
});

// Solana wallet setup
const solanaNetwork = WalletAdapterNetwork.Devnet; // or Mainnet
const solanaEndpoint = clusterApiUrl(solanaNetwork);
const solanaWallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter({ network: solanaNetwork }),
];

ReactDOM.render(
  <React.StrictMode>
    <ConnectionProvider endpoint={solanaEndpoint}>
      <WalletProvider wallets={solanaWallets} autoConnect>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  </React.StrictMode>,
  document.getElementById('root')
);