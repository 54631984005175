import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ethers } from 'ethers';
import { useWeb3Modal, useSwitchNetwork, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Spinner from '../Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import BridgeABI from '../Bridge.json';
import { Connection, PublicKey, ComputeBudgetProgram, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Program, AnchorProvider, BN } from '@project-serum/anchor';
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction } from '@solana/spl-token';
import { useWallet } from '@solana/wallet-adapter-react';
import { MINIMAL_BRIDGE_IDL } from '../minimal_bridge_idl';
import { keccak256 } from 'ethereum-cryptography/keccak';

const SOLANA_PROGRAM_ID = new PublicKey('C4j9g3Sp7XC3kkJxBju7zypkfSfPBcd2kLrvbL6xqQeT');
const SOLANA_TOKEN_MINT_ADDRESS = new PublicKey('AYgw6k8ZzjkmTMAqrV8hbBe53uM8CrWwcbHuk5RMh6Qt');

const config = {
  ethereum: {
    RPC_URL: 'https://cloudflare-eth.com',
    BRIDGE_ADDRESS: '0x8294AfF447fA42F7d9746f7F9297D6A92aBAFEF8'
  },
  binance: {
    RPC_URL: 'https://bsc-dataseed4.bnbchain.org',
    BRIDGE_ADDRESS: '0x9280efC1bCEF237e32614A953358e5D3DadAe9BA'
  },
  base: {
    RPC_URL: 'https://mainnet.base.org',
    BRIDGE_ADDRESS: '0xD7BEa66CE7F4d37cff398C22ed570080E8b990C3'
  }
};

const RedeemModal = ({ isOpen, onClose, blockchainOptions, setSendAmount, setReceiveAmount, fetchTokenBalance, fetchToTokenBalance }) => {
  const [selectedBlockchain, setSelectedBlockchain] = useState(blockchainOptions[0].value);
  const [pendingRedeems, setPendingRedeems] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transferStatus, setTransferStatus] = useState('');
  const [processingIndex, setProcessingIndex] = useState(null);
  const [isLoadingRedeems, setIsLoadingRedeems] = useState(false);
  const { switchNetwork } = useSwitchNetwork();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const wallet = useWallet();
  const abortControllerRef = useRef(null);

  const getNativeTokenSymbol = (blockchain) => {
    switch (blockchain) {
      case 'ethereum':
        return 'ETH';
      case 'binance':
        return 'BNB';
      case 'base':
        return 'ETH';
      case 'sol':
        return 'SOL';
      default:
        return 'ETH';
    }
  };

  const fetchPendingRedeemsSolana = useCallback(debounce(async (address, signal) => {
    setIsLoadingRedeems(true);
    try {
      console.log('Fetching pending redeems for Solana address:', address);
      const apiUrl = 'https://refined-genuinely-bluegill.ngrok-free.app/pending-redeems-solana';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ address }),
        signal
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch Solana pending redeems: ${response.status} - ${errorText}`);
      }
  
      const data = await response.json();
      if (!data.pendingRedeems) {
        throw new Error("Failed to fetch Solana pending redeems: no pending redeems in response");
      }
  
      setPendingRedeems(data.pendingRedeems);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching Solana pending redeems:', error.message);
        toast.error(`Error fetching Solana pending redeems: ${error.message}`);
      }
    } finally {
      setIsLoadingRedeems(false);
    }
  }, 300), []);

  const fetchPendingRedeems = useCallback(debounce(async (address, blockchain, signal) => {
    setIsLoadingRedeems(true);
    try {
      console.log('Fetching pending redeems for address:', address, 'blockchain:', blockchain);
      const apiUrl = 'https://refined-genuinely-bluegill.ngrok-free.app/pending-redeems';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ address, chain: blockchain }),
        signal
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch pending redeems: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      if (!data.pendingRedeems) {
        throw new Error("Failed to fetch pending redeems: no pending redeems in response");
      }

      setPendingRedeems(data.pendingRedeems);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching pending redeems:', error.message);
        toast.error(`Error fetching pending redeems: ${error.message}`);
      }
    } finally {
      setIsLoadingRedeems(false);
    }
  }, 300), []);

  useEffect(() => {
    if (isOpen && isConnected) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      if (selectedBlockchain === 'sol') {
        if (wallet.publicKey) {
          fetchPendingRedeemsSolana(wallet.publicKey.toBase58(), signal);
        } else {
          // Clear pending redeems if the wallet is not connected
          setPendingRedeems([]);
        }
      } else {
        fetchPendingRedeems(address, selectedBlockchain, signal);
      }
    }
  }, [isOpen, isConnected, address, selectedBlockchain, fetchPendingRedeems, fetchPendingRedeemsSolana, wallet.publicKey]);

  const handleRedeemSolana = async (redeem, index) => {
    setIsProcessing(true);
    setProcessingIndex(index);
    setTransferStatus('Processing Solana redemption');

    try {
      if (!wallet.connected) {
        toast.error('Solana wallet is not connected. Please connect your wallet and try again.');
        setTransferStatus('Solana wallet not connected');
        return;
      }

      const userAddress = wallet.publicKey.toBase58();
      // Fetch the secret from the server
      const secretEndpoint = 'https://refined-genuinely-bluegill.ngrok-free.app/retrieve-secret-solana';
      const response = await fetch(secretEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lockHash: redeem.lockHash, userAddress })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to retrieve secret');
      }
      const lockData = data.lockData;
      console.log("Lock Data:", lockData);

      const connection = new Connection("https://light-floral-river.solana-mainnet.quiknode.pro/24fe3cba2cf2a0f20c850f18c06e914106b955cd/", 'confirmed');
      const provider = new AnchorProvider(
        connection,
        wallet,
        { preflightCommitment: 'confirmed' }
      );
      const SOLANA_PROGRAM_ID = new PublicKey('C4j9g3Sp7XC3kkJxBju7zypkfSfPBcd2kLrvbL6xqQeT');
      const SOLANA_TOKEN_MINT_ADDRESS = new PublicKey('AYgw6k8ZzjkmTMAqrV8hbBe53uM8CrWwcbHuk5RMh6Qt');
      const program = new Program(MINIMAL_BRIDGE_IDL, SOLANA_PROGRAM_ID, provider);

      const [bridgeStatePda] = await PublicKey.findProgramAddress(
        [Buffer.from('bridge_state_main')],
        SOLANA_PROGRAM_ID
      );

      const [bridgeAuthority] = await PublicKey.findProgramAddress(
        [Buffer.from('bridge_authority')],
        SOLANA_PROGRAM_ID
      );

      const userATA = await getAssociatedTokenAddress(
        SOLANA_TOKEN_MINT_ADDRESS,
        wallet.publicKey
      );

      const bridgeATA = await getAssociatedTokenAddress(
        SOLANA_TOKEN_MINT_ADDRESS,
        bridgeAuthority,
        true // allowOwnerOffCurve
      );

      console.log('Account Addresses:');
      console.log('User:', wallet.publicKey.toBase58());
      console.log('Bridge Token Account:', bridgeATA.toBase58());
      console.log('User Token Account:', userATA.toBase58());
      console.log('Bridge Authority:', bridgeAuthority.toBase58());

      const balance = await connection.getBalance(wallet.publicKey);
      console.log("Wallet balance:", balance / LAMPORTS_PER_SOL, "SOL");
      setTransferStatus('Preparing to release tokens on Solana chain');

      const { blockhash } = await connection.getLatestBlockhash('finalized');
      const amount = new BN(lockData.amount);
      console.log('Amount:', amount.toString());
      console.log('Recipient:', wallet.publicKey.toBase58());
      console.log('Nonce:', new BN(lockData.nonce || 0).toString());
      console.log('MerkleProof:', lockData.merkleProof);

      const merkleProofBuffers = lockData.merkleProof.map(proof => Buffer.from(proof.slice(2), 'hex'));

      const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
        units: 600000 // Increased from default
      });

      let transaction = new Transaction().add(modifyComputeUnits);

      // Check if user ATA exists and create if it doesn't
      const userATAInfo = await connection.getAccountInfo(userATA);
      if (!userATAInfo) {
        console.log("Creating user ATA:", userATA.toBase58());
        transaction.add(
          createAssociatedTokenAccountInstruction(
            wallet.publicKey, // payer
            userATA, // ata
            wallet.publicKey, // owner
            SOLANA_TOKEN_MINT_ADDRESS // mint
          )
        );
      }

      const releaseTokensIx = await program.methods.releaseTokens(
        amount,
        wallet.publicKey,
        new BN(lockData.nonce || 0),
        merkleProofBuffers
      )
        .accounts({
          user: wallet.publicKey,
          bridgeState: bridgeStatePda,
          bridgeTokenAccount: bridgeATA,
          recipientTokenAccount: userATA,
          bridgeAuthority: bridgeAuthority,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction();

      transaction.add(releaseTokensIx);
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = wallet.publicKey;

      const signedTransaction = await wallet.signTransaction(transaction);
      const serializedTransaction = signedTransaction.serialize();

      setTransferStatus('Sending transaction');
      const tx = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: 'confirmed'
      });

      console.log("Transaction sent:", tx);
      setTransferStatus('Transaction sent, awaiting confirmation');

      // Function to check transaction confirmation
      const confirmTransaction = async (signature, maxAttempts = 30, interval = 10000) => {
        let attempts = 0;
        while (attempts < maxAttempts) {
          const status = await connection.getSignatureStatus(signature);
          if (status.value) {
            if (status.value.err) {
              throw new Error('Transaction failed: ' + JSON.stringify(status.value.err));
            }
            if (status.value.confirmationStatus === 'confirmed' || status.value.confirmationStatus === 'finalized') {
              return true;
            }
          }
          await new Promise(resolve => setTimeout(resolve, interval));
          attempts++;
        }
        throw new Error('Transaction confirmation timeout');
      };

      // Wait for confirmation
      await confirmTransaction(tx);

      console.log("Transaction confirmed:", tx);
      setTransferStatus('Transaction confirmed');
      toast.success('Tokens released successfully on Solana chain');

      // Update UI
      setPendingRedeems(prevRedeems => prevRedeems.filter((_, i) => i !== index));
      setSendAmount('');
      setReceiveAmount(0);
      fetchTokenBalance();
      fetchToTokenBalance(address, 'sol');

      // Call delete API
      try {
        const deleteResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/delete-merkle-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ commitment: lockData.lockHash })
        });
        if (!deleteResponse.ok) {
          throw new Error('Failed to delete merkle data');
        }
        console.log("Delete API called successfully");
      } catch (deleteError) {
        console.error("Error calling delete API:", deleteError);
        // toast.error("Failed to update backend. Please contact support to resolve this issue.");
        // // You might want to implement a retry mechanism or log this for manual resolution
      }

    } catch (error) {
      console.error('Error during Solana token redeem:', error);
      if (error.message.includes('Transaction confirmation timeout')) {
        toast.error('Transaction confirmation timed out. Please check your wallet for the transaction status and contact support if the issue persists.');
      } else {
        toast.error(`Error: ${error.message}`);
      }
      setTransferStatus(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setProcessingIndex(null);
    }
  };

  const handleRedeem = async (redeem, index) => {
    setIsProcessing(true);
    setProcessingIndex(index);
    setTransferStatus('Processing redemption');

    try {
      await switchToSelectedNetwork();

      const userAddress = address;
      console.log("Selected Blockchain:", selectedBlockchain);
      console.log("User Address:", userAddress);

      const selectedChain = blockchainOptions.find(option => option.value === selectedBlockchain);
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      console.log("Redeem", redeem);

      // Fetch the secret from the server
      const secretEndpoint = 'https://refined-genuinely-bluegill.ngrok-free.app/retrieve-secret';
      console.log("Secret Endpoint:", secretEndpoint);

      const response = await fetch(secretEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lockHash: redeem.lockHash, userAddress })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to retrieve secret: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      const secret = data.secret;
      console.log("Lock Hash:", redeem.lockHash, "Secret:", secret);

      const secretBytes = ethers.utils.arrayify(
        secret.startsWith('0x') ? secret : '0x' + secret
      );
      console.log("Secret Bytes:", secretBytes);
      const bridgeContract = new ethers.Contract(selectedChain.bridgeContract, BridgeABI, signer);
      const redeemTx = await bridgeContract.releaseTokens(
        redeem.lockHash,
        secretBytes
      );

      // Transaction confirmation with retry
      const maxRetries = 10;
      const retryInterval = 5000; // 5 seconds
      let confirmed = false;

      for (let attempt = 0; attempt < maxRetries && !confirmed; attempt++) {
        try {
          console.log(`Waiting for transaction confirmation, attempt ${attempt + 1}...`);
          const receipt = await redeemTx.wait();
          if (receipt.status === 1) {
            confirmed = true;
            console.log('Transaction confirmed after', attempt + 1, 'attempts');
          } else {
            throw new Error('Transaction failed');
          }
        } catch (confirmError) {
          console.log(`Confirmation attempt ${attempt + 1} failed:`, confirmError.message);
          if (attempt < maxRetries - 1) {
            console.log(`Retrying in ${retryInterval / 1000} seconds...`);
            await new Promise(resolve => setTimeout(resolve, retryInterval));
          }
        }
      }

      if (!confirmed) {
        throw new Error(`Transaction not confirmed after ${maxRetries} attempts`);
      }

      setTransferStatus('Redemption confirmed on target chain');
      toast.success('Redeem successful');

      setPendingRedeems(prevRedeems => prevRedeems.filter((_, i) => i !== index));

      setSendAmount('');
      setReceiveAmount(0);
      fetchTokenBalance();
      fetchToTokenBalance(address, selectedBlockchain);

      // Call delete API
      try {
        const deleteResponse = await fetch('https://refined-genuinely-bluegill.ngrok-free.app/delete-lock-hash', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lockHash: redeem.lockHash })
        });
        if (!deleteResponse.ok) {
          throw new Error('Failed to delete merkle data');
        }
        console.log("Delete API called successfully");
      } catch (deleteError) {
        console.error("Error calling delete API:", deleteError);
        // toast.error("Failed to update backend. Please contact support to resolve this issue.");
        // You might want to implement a retry mechanism or log this for manual resolution
      }

    } catch (error) {
      console.error('Error during token redeem:', error);
      setTransferStatus(`Error: ${error.message}`);
      toast.error(error.data ? error.data.message : 'Error during token redeem');
    } finally {
      setIsProcessing(false);
      setProcessingIndex(null);
    }
  };

  const handleBlockchainChange = (e) => {
    setSelectedBlockchain(e.target.value);
    setPendingRedeems([]); // Clear pending redeems when changing blockchain
  };

  const switchToSelectedNetwork = async () => {
    const selectedChain = blockchainOptions.find(option => option.value === selectedBlockchain);
    if (chainId !== selectedChain.chainId) {
      console.log('Switching to network:', selectedChain);
      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        await provider.send("wallet_switchEthereumChain", [{ chainId: ethers.utils.hexValue(selectedChain.chainId) }]);
      } catch (error) {
        if (error.code === 4902) {
          try {
            const selectedToBlockchain = blockchainOptions.find(option => option.chainId === selectedChain.chainId);
            const provider = new ethers.providers.Web3Provider(walletProvider);
            await provider.send("wallet_addEthereumChain", [
              {
                chainId: ethers.utils.hexValue(selectedChain.chainId),
                chainName: selectedToBlockchain.label,
                rpcUrls: [selectedToBlockchain.rpcUrl],
                nativeCurrency: {
                  name: selectedToBlockchain.label,
                  symbol: getNativeTokenSymbol(selectedToBlockchain.value),
                  decimals: 18
                },
                blockExplorerUrls: [selectedToBlockchain.explorerUrl]
              },
            ]);
          } catch (addError) {
            console.error('Failed to add network:', addError);
            throw new Error(`Failed to add network: ${addError.message}`);
          }
        } else {
          console.error('Failed to switch network:', error);
          throw new Error(`Failed to switch network: ${error.message}`);
        }
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center overflow-y-auto">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="relative bg-black p-8 border-2 rounded-[12px] border-[#FF00DD9F] w-full lg:w-[623px] flex flex-col gap-7 top-[10%] relative m-[10px]">
        <div className="flex justify-between items-center">
          <h3 className="text-[#FF0083] text-[24px] font-bold leading-7">Find Redeem</h3>
          <p onClick={onClose} className="border border-white rounded-full h-[20px] w-[20px] flex justify-center items-center p-[11px] cursor-pointer">x</p>
        </div>
        <div className="flex flex-col gap-4">
          <select
            className="cursor-pointer w-full h-[46px] bg-[#151515] border border-[#343434] rounded-[8px] py-[9px] px-[7px]"
            value={selectedBlockchain}
            onChange={handleBlockchainChange}
          >
            {blockchainOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-4">
          <h4 className="leading-5 text-[16px] font-medium">Available for Redeem</h4>
          <div className="flex flex-col gap-4 max-h-[200px] overflow-y-auto custom-scrollbar">
            {isLoadingRedeems ? (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            ) : pendingRedeems.length > 0 ? (
              pendingRedeems.map((redeem, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="text-white">
                    Token Amount: {
                      redeem.amount 
                        ? selectedBlockchain === 'sol'
                          ? redeem.amount / 10 ** 7
                          : ethers.utils.formatUnits(redeem.amount, 18)
                        : '0.00'
                    }
                  </span>
                  <button
                    className="bg-[#EC4899] flex justify-center items-center rounded-[8px] h-[30px] px-[10px] text-black font-liberation font-light text-sm"
                    onClick={() => selectedBlockchain === 'sol' ? handleRedeemSolana(redeem, index) : handleRedeem(redeem, index)}
                    disabled={isProcessing && processingIndex === index}
                  >
                    {isProcessing && processingIndex === index ? <Spinner /> : 'Redeem Now'}
                  </button>
                </div>
              ))
            ) : (
              <span className="text-white">No pending redeems</span>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-2">
          {selectedBlockchain === 'sol' && !wallet.connected && (
            <WalletMultiButton 
            style={{
              backgroundColor: '#EC4899',
              color: 'black',
              fontSize: '16px',
              height: '44px',
              borderRadius: '8px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 300,
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Connect Solana Wallet
          </WalletMultiButton>
          )}
          <button
            onClick={onClose}
            className="font-liberation text-[16px] leading-4 font-normal w-full h-[44px] bg-transparent rounded-[8px] p-[14px] font-bold"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
