export const MINIMAL_BRIDGE_IDL = {
    "version": "0.1.0",
    "name": "optimized_bridge",
    "instructions": [
      {
        "name": "initialize",
        "accounts": [
          { "name": "payer", "isMut": true, "isSigner": true },
          { "name": "bridgeState", "isMut": true, "isSigner": false },
          { "name": "systemProgram", "isMut": false, "isSigner": false }
        ],
        "args": [
          { "name": "owner", "type": "publicKey" }
        ]
      },
      {
        "name": "transferTokens",
        "accounts": [
          { "name": "user", "isMut": true, "isSigner": true },
          { "name": "userTokenAccount", "isMut": true, "isSigner": false },
          { "name": "bridgeTokenAccount", "isMut": true, "isSigner": false },
          { "name": "bridgeState", "isMut": false, "isSigner": false },
          { "name": "bridgeAuthority", "isMut": false, "isSigner": false },
          { "name": "tokenProgram", "isMut": false, "isSigner": false }
        ],
        "args": [
          { "name": "amount", "type": "u64" },
          { "name": "recipient", "type": { "array": ["u8", 32] } },
          { "name": "targetChain", "type": "u8" }
        ]
      },
      {
        "name": "updateMerkleRoot",
        "accounts": [
          { "name": "owner", "isMut": false, "isSigner": true },
          { "name": "bridgeState", "isMut": true, "isSigner": false }
        ],
        "args": [
          { "name": "newRoot", "type": { "array": ["u8", 32] } }
        ]
      },
      {
        "name": "releaseTokens",
        "accounts": [
          { "name": "user", "isMut": false, "isSigner": true },
          { "name": "bridgeState", "isMut": false, "isSigner": false },
          { "name": "bridgeTokenAccount", "isMut": true, "isSigner": false },
          { "name": "recipientTokenAccount", "isMut": true, "isSigner": false },
          { "name": "bridgeAuthority", "isMut": false, "isSigner": false },
          { "name": "tokenProgram", "isMut": false, "isSigner": false }
        ],
        "args": [
          { "name": "amount", "type": "u64" },
          { "name": "recipient", "type": "publicKey" },
          { "name": "nonce", "type": "u64" },
          { "name": "merkleProof", "type": { "vec": { "array": ["u8", 32] } } }
        ]
      }
    ],
    "accounts": [
      {
        "name": "BridgeState",
        "type": {
          "kind": "struct",
          "fields": [
            { "name": "owner", "type": "publicKey" },
            { "name": "merkleRoot", "type": { "array": ["u8", 32] } }
          ]
        }
      }
    ],
    "events": [
      {
        "name": "TokensLocked",
        "fields": [
          { "name": "user", "type": "publicKey", "index": false },
          { "name": "amount", "type": "u64", "index": false },
          { "name": "recipient", "type": { "array": ["u8", 32] }, "index": false },
          { "name": "targetChain", "type": "u8", "index": false },
          { "name": "timestamp", "type": "i64", "index": false }
        ]
      },
      {
        "name": "MerkleRootUpdated",
        "fields": [
          { "name": "newRoot", "type": { "array": ["u8", 32] }, "index": false }
        ]
      },
      {
        "name": "TokensReleased",
        "fields": [
          { "name": "recipient", "type": "publicKey", "index": false },
          { "name": "amount", "type": "u64", "index": false },
          { "name": "nonce", "type": "u64", "index": false }
        ]
      },
      {
        "name": "TokensRecovered",
        "fields": [
          { "name": "owner", "type": "publicKey", "index": false },
          { "name": "amount", "type": "u64", "index": false }
        ]
      }
    ],
    "errors": [
      { "code": 6000, "name": "NotAuthorized" },
      { "code": 6001, "name": "InvalidMerkleProof" }
    ]
  }
  