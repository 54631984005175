import React from 'react';
import './Spinner.css';

const Spinner = () => (
  <div className="spinner-border text-white" role="status">
    <span className="visually-hidden"></span>
  </div>
);

export default Spinner;
